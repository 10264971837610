.detf-owned-assets-table {
    width: 100%;
    margin-top: 20px;
}

.detf-owned-assets-header-item-token,
.detf-owned-assets-header-item-liquidity,
.detf-owned-assets-header-item-weight {
    text-align: left;
    font-size: 14px;
}

.detf-owned-assets-body-row {
    width: 100%;
}

.detf-owned-assets-body-item-token,
.detf-owned-assets-body-item-liquidity,
.detf-owned-assets-body-item-weight {
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 24px;
}

.detf-owned-assets-token-logo {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
}

.detf-owned-token-logo {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 10px;
}

.detf-owned-assets-token-link {
    color: #000000;
    text-decoration: none;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .detf-owned-assets-table {
        display: none;
    }

    .detf-owned-assets-table-mobile {
        width: 100%;
        table-layout: fixed;
        margin-top: 24px;
    }

    .detf-owned-assets-body-row-mobile {
        width: 100%;
    }

    .detf-owned-assets-body-item-mobile {
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 24px;
    }

    .detf-owned-assets-token-logo-mobile {
        display: flex;
        flex-direction: row;
        text-align: left;
        align-items: center;
    }

    .detf-owned-token-logo-mobile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .detf-owned-assets-header-item-token-mobile {
        width: 80%;
        text-align: left;
        font-size: 14px;
    }

    .detf-owned-assets-header-item-weight-mobile {
        width: 20%;
        text-align: left;
        font-size: 14px;
    }

    .detf-owned-assets-body-item-token-mobile {
        width: 80%;
    }

    .detf-owned-assets-body-item-weight-mobile {
        width: 20%;
    }

    .detf-owned-assets-header-item-liquidity-mobile,
    .detf-owned-assets-body-item-liquidity-mobile {
        display: none;
    }

}

/* Medium devices (tablets, 720px and up) */

@media (min-width: 720px) {
    .detf-owned-assets-table-mobile {
        display: none;
    }

    .detf-owned-assets-header-item-liquidity,
    .detf-owned-assets-body-item-liquidity {
        display: none;
    }
}

/* Large devices (desktops, 960px and up) */

@media (min-width: 960px) {
    .detf-owned-assets-table-mobile {
        display: none;
    }

    .detf-owned-assets-header-item-liquidity,
    .detf-owned-assets-body-item-liquidity {
        display: table-cell;
    }
}

/*Extra large devices (large desktops, 1025px and up)*/

@media (min-width: 1025px) {
    .detf-owned-assets-table-mobile {
        display: none;
    }

    .detf-owned-assets-header-item-liquidity,
    .detf-owned-assets-body-item-liquidity {
        display: table-cell;
    }
}