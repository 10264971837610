.invalid-portolfio-range {
    position: relative;
}

.invalid-portolfio-range-chart {
    position: absolute;
    width: 100%;
    height: 100%;
}

.invalid-portolfio-range-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #EEEEEE;
    opacity: 0.75;
    color: #875CFF;
    font-size: 16px;
}

.invalid-portolfio-range-overlay-info {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}