* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  ;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E3E3E3;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.app h1 {
  font-size: 62px;
  font-weight: 700;
  color: #875CFF;
}

.app h2 {
  font-size: 24px;
  font-weight: 700;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
  .app {
    width: 100vw;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
  /* .app {
    width: 100vw;
  } */
}