.token-table-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEEEE;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 24px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
    color: #909090;
    padding-left: 20px;
    padding-right: 20px;
}

.token-table-row-items {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
}

.token-table-row-item-asset {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.token-table-row-item-value,
.token-table-row-item-price,
.token-table-row-item-return {
    width: 15%;
}

.token-table-row-item-cta {
    width: 25%;
    text-align: right;
    vertical-align: middle;
    font-size: 16px;
    color: #000000;
}

.token-table-row-item-logo {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.token-table-row-item-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.token-table-row-item-asset-name {
    font-size: 16px;
    color: #000000;
}

.token-table-row-item-asset-symbol {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
}

.token-table-row-item-name-link {
    text-decoration: none;
}

.token-table-row-item-link {
    text-decoration: none;
    color: #000000
}

.token-table-row-item-link-to-asset {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    padding-left: 12px;
}

.token-table-row-item-link-to-swap {
    color: #000000;
    text-decoration: none;
}