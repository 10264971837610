.token-index-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.token-index-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    margin-bottom: 10px;
}



.token-index-header-item-token,
.token-index-header-item-price,
.token-index-header-item-one-day,
.token-index-header-item-one-week,
.token-index-header-item-one-month,
.token-index-header-item-volume-24h,
.token-index-header-item-market-cap {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
}


.token-index-header-item-token {
    width: 30%;
    padding-left: 24px;
}

.token-index-header-item-price {
    width: 15%;
}

.token-index-header-item-one-day,
.token-index-header-item-one-week,
.token-index-header-item-one-month {
    width: 8.33%;
}

.token-index-header-item-volume-24h,
.token-index-header-item-market-cap {
    width: 15%;
}

.token-index-row-items {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEEEE;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.token-index-row-item-token,
.token-index-row-item-price,
.token-index-row-item-one-day,
.token-index-row-item-one-week,
.token-index-row-item-one-month,
.token-index-row-item-volume-24h,
.token-index-row-item-market-cap {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.token-index-row-item-price {
    width: 15%;
    color: #909090;
}

.token-index-row-item-one-day,
.token-index-row-item-one-week,
.token-index-row-item-one-month {
    width: 8.33%;
}

.token-index-row-item-volume-24h,
.token-index-row-item-market-cap {
    width: 15%;
    color: #909090;
}

.token-index-row-item-token {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
    width: 30%;
}

.token-index-row-item-logo {
    width: 44px;
    height: 44px;
    margin-right: 10px;
    border-radius: 50px;
}

.token-index-row-item-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.token-index-row-item-link {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    cursor: default;
}

.token-index-container-mobile {
    display: none;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .token-index-container {
        display: none;
    }

    .token-index-container-mobile {
        width: 100%;
        min-width: 278px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .token-index-header-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: left;
    }

    .token-index-header-item-price-mobile,
    .token-index-header-item-one-day-mobile,
    .token-index-header-item-one-week-mobile,
    .token-index-header-item-one-month-mobile,
    .token-index-header-item-volume-24h,
    .token-index-header-item-market-cap {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }

    .token-index-row-items-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #EEEEEE;
        border: 1px solid #ffffff;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 12px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        color: #000000;
    }

    .token-index-row-item-token-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .token-index-row-item-token-header-mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .token-index-row-item-name-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        font-size: 20px;
    }

    .token-index-row-item-logo-mobile {
        width: 44px;
        height: 44px;
        margin-right: 10px;
        border-radius: 50px;
    }

    .token-index-row-item-table {
        width: 100%;
        margin-top: 20px;
        color: #909090;
    }

    .token-index-row-item-table-header {
        text-align: right;
        vertical-align: middle;
        line-height: 20px;
        padding-right: 6px;
    }

    .token-index-row-item-table-cell {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        text-align: left;
        padding-left: 6px;
        line-height: 20px;
    }

}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .token-index-container-mobile {
        display: none;
    }

    .token-index-container {
        max-width: 959px;
    }

    .token-index-header-item-token {
        width: 40%;
    }

    .token-index-header-item-price {
        width: 20%;
    }

    .token-index-header-item-one-day,
    .token-index-header-item-one-week {
        display: inherit;
        width: 13.33%;
    }

    .token-index-header-item-market-cap {
        display: inherit;
        width: 13.33%;
    }

    .token-index-row-item-token {
        width: 40%;
    }

    .token-index-row-item-price {
        width: 20%;
    }

    .token-index-row-item-one-day,
    .token-index-row-item-one-week {
        display: inherit;
        width: 13.33%;
    }

    .token-index-row-item-market-cap {
        display: inherit;
        width: 13.33%;
    }

    .token-index-header-item-one-month,
    .token-index-row-item-one-month,
    .token-index-header-item-volume-24h,
    .token-index-row-item-volume-24h {
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .token-index-container-mobile {
        display: none;
    }

    .token-index-container {
        max-width: 1024px;
    }

    .token-index-header-item-token {
        width: 30%;
    }

    .token-index-header-item-price {
        width: 15%;
    }

    .token-index-header-item-one-day,
    .token-index-header-item-one-week,
    .token-index-header-item-one-month {
        display: inherit;
        width: 13.75%;
    }

    .token-index-header-item-market-cap {
        display: inherit;
        width: 13.75%;
    }

    .token-index-row-item-token {
        width: 30%;
    }

    .token-index-row-item-price {
        width: 15%;
    }

    .token-index-row-item-one-day,
    .token-index-row-item-one-week,
    .token-index-row-item-one-month {
        display: inherit;
        width: 13.75%;
    }

    .token-index-row-item-market-cap {
        display: inherit;
        width: 13.75%;
    }

    .token-index-header-item-volume-24h,
    .token-index-row-item-volume-24h {
        display: none;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .token-index-container-mobile {
        display: none;
    }

    .token-index-container {
        max-width: 1280px;
    }

    .token-index-header-item-volume-24h,
    .token-index-row-item-volume-24h {
        display: inherit;
    }
}