.connect-provider {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e3e3e3;
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 160px;
    position: relative;
}

.connect-provider-box-coinbase,
.connect-provider-box-metamask,
.connect-provider-box-wallet-connect {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #eeeeee;
    margin-bottom: 20px;
    border: 1px solid white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.connect-provider-notice {
    width: 100%;
    background-color: #e3e3e3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #909090;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {

    .connect-provider-box-metamask,
    .connect-provider-box-wallet-connect {
        display: none;
    }

    .connect-provider {
        min-width: 272px;
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 80px;
    }

    .connect-provider-box-coinbase {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .provider-logo {
        margin-bottom: 40px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .connect-provider {
        max-width: 1280px;
    }
}