.inline-dropdown {
    border: none;
    border-bottom: 2px solid #000000;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    position: relative;
    background-color: #E3E3E3;
}

.inline-dropdown-box {
    position: absolute;
    top: 38px;
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
    background-color: #fff;
    width: 270px;
    z-index: 10;
}

.inline-dropdown-box-options {
    margin: 0;
    top: 16px;
}

.inline-dropdown-box-options:hover {
    color: #875CFF;
}

.inline-dropdown-box-options:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .inline-dropdown {
        font-size: 24px;
    }

    .inline-dropdown-box {
        top: 32px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {}