.button-primary-standard,
.button-primary-standard-long,
.button-primary-slim,
.button-secondary-standard,
.button-secondary-slim,
.button-primary-standard-loading,
.button-primary-slim-loading,
.button-secondary-standard-loading,
.button-secondary-slim-loading,
.button-primary-standard-disabled,
.button-primary-standard-long-disabled,
.button-primary-slim-disabled,
.button-secondary-standard-disabled,
.button-secondary-slim-disabled {
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    min-width: 228px;
    font-size: 16px;
    font-weight: 700;
    padding-left: 28px;
    padding-right: 28px;
}

.button-primary-small,
.button-primary-small-disabled,
.button-secondary-small,
.button-secondary-small-disabled {
    height: 40px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
}

.button-primary-small,
.button-primary-standard,
.button-primary-standard-long,
.button-primary-slim,
.button-primary-standard-loading,
.button-primary-slim-loading {
    border: 2px solid #000000;
    background-color: #000000;
    color: #ffffff;
}

.button-secondary-small,
.button-secondary-standard,
.button-secondary-slim,
.button-secondary-standard-loading,
.button-secondary-slim-loading {
    border: 2px solid #000000;
    background-color: transparent;
    color: #000000;
}

.button-primary-small-disabled,
.button-secondary-small-disabled,

.button-primary-standard-disabled,
.button-primary-standard-long-disabled,
.button-primary-slim-disabled,
.button-secondary-standard-disabled,
.button-secondary-slim-disabled {
    border: 2px solid #CACACA;
    background-color: #CACACA;
    color: #ffffff;
}

.button-primary-standard,
.button-primary-standard-long,
.button-primary-standard-long-disabled,
.button-secondary-standard,
.button-primary-standard-loading,
.button-secondary-standard-loading,
.button-primary-standard-disabled,
.button-secondary-standard-disabled {
    height: 66px;
}

.button-primary-slim,
.button-secondary-slim,
.button-primary-slim-loading,
.button-secondary-slim-loading,
.button-primary-slim-disabled,
.button-secondary-slim-disabled {
    height: 44px;
}

.button-primary-standard-long,
.button-primary-standard-long-disabled {
    width: 100%;
}

.button-loading-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text-link,
.text-link-underline,
.text-link-forward,
.text-link-back,
.text-link-forward-logout {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.text-link-underline {
    border-bottom: solid 2px #000000;
}

.fa-right-long {
    padding-right: 14px;
    font-size: 22px;
    transform: translateY(+10%);
}

.fa-left-long {
    padding-right: 14px;
    font-size: 22px;
    transform: translateY(+10%);
}

.fa-right-from-bracket {
    padding-left: 10px;
    font-size: 22px;
    transform: translateY(+10%);
}