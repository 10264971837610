.withdraw-success {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 700;
    color: #000000;
}

.withdraw-success-tick {
    margin-bottom: 60px;
    height: 120px;
    width: 120px;
}

.withdraw-success-text {
    width: 70%;
    margin-bottom: 60px;
    color: #00BF6F;
    font-size: 36px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .withdraw-success {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .withdraw-success-tick {
        margin-bottom: 30px;
        height: 60px;
        width: 60px;
    }

    .withdraw-success-text {
        width: 100%;
        margin-bottom: 30px;
        font-size: 24px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {}