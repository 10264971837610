.account-detf-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 160px;
}

.account-detf-title {
    padding-top: 80px;
    padding-bottom: 80px;
}

.account-detf-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.account-detf-header-item-detf,
.account-detf-header-item-value,
.account-detf-header-item-return,
.account-detf-header-item-status,
.account-detf-header-item-deposit,
.account-detf-header-item-toggle {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

}

.account-detf-header-item-detf {
    width: 30%;
}

.account-detf-header-item-value {
    width: 15%;
}

.account-detf-header-item-return {
    width: 10%;
}

.account-detf-header-item-status {
    width: 25%;
}

.account-detf-header-item-deposit {
    width: 10%;
}

.account-detf-header-item-toggle {
    width: 10%;
}

.account-detf-container-placeholder {
    width: 100%;
    height: 400px;
    /* background-image: url("../../assets/images/account_table_row_placeholder.png"); */
    position: relative;
}

.account-detf-container-placeholder-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #E7E7E7;
    border-radius: 4px;
    border: solid 1px #ffffff;
    opacity: 0.9;
    font-size: 24px;
    font-weight: 700;
}

.account-detf-container-placeholder-overlay-info {
    position: absolute;
    top: 14px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    padding-left: 24px;
    padding-right: 24px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .account-detf-header {
        display: none;
    }

    .account-detf-title {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .account-detf-container {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .account-detf-title h1 {
        font-size: 40px;
        line-height: 50px;
    }

    .account-detf-header-mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: default;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .account-detf-header-item-detf-mobile,
    .account-detf-header-item-value-mobile,
    .account-detf-header-item-return-mobile,
    .account-detf-header-item-status-mobile,
    .account-detf-header-item-deposit-mobile,
    .account-detf-header-item-toggle-mobile {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .account-detf-header-mobile {
        display: none;
    }

    .account-detf-header-item-status {
        display: none;
    }

    .account-detf-header-item-detf {
        width: 40%;
    }

    .account-detf-header-item-value,
    .account-detf-header-item-return {
        width: 15%;
    }

    .account-detf-header-item-deposit,
    .account-detf-header-item-toggle {
        width: 15%;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .account-detf-header-mobile {
        display: none;
    }

    .account-detf-header-item-status {
        display: inherit;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .account-detf-header-mobile {
        display: none;
    }

    .account-detf-container {
        max-width: 1280px;
    }

    .account-detf-header-item-status {
        display: inherit;
    }
}