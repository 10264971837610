.dropdown {
    position: absolute;
    top: 0px;
    left: 0px;
    background: #ffffff;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    text-align: left;
    border-radius: 4px;
}

.dropdown>p {
    margin: 0;
    padding: 25px;
    border-bottom: 1px solid #EEEEEE;
    min-width: 140px;
    width: 176px;

}

.dropdown>p:hover {
    color: #ffffff;
    background-color: #000000;
}

.dropdown>p:last-child {
    border-bottom: 4px solid #000000;
}