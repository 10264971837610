.top-detfs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.top-detfs-boxes {
    display: grid;
    margin-top: 50px;
    list-style: none;
}

.top-detfs-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 8px;
    border: 1px solid white;
    padding: 20px;
}

.top-detfs-box-loading {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 8px;
    border: 1px solid white;
    padding: 20px;
}

.top-detfs-box-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: left;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
}

.top-detfs-box-header-title {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.top-detfs-box-header-title-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.top-detfs-box-header-title-name-category {
    font-size: 16px;
    line-height: 16px;
}

.top-detfs-box-header-title-name-dimension {
    font-size: 24px;
    line-height: 24px;
}

.top-detfs-box-header-title-logo {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.top-detfs-box-chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.top-detfs-container-button-wrapper {
    width: 100%;
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .top-detfs-container {
        max-width: 719px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 40px;
        padding-bottom: 80px;
    }

    .top-detfs-boxes {
        margin-top: 30px;
        grid-template-rows: repeat(3, minmax(0, 1fr));
        grid-row-gap: 24px;
    }

    .top-detfs-box {
        height: 300px;
    }

    .top-detfs-box-header {
        display: flex;
        flex-direction: column;
    }

    .top-detfs-box-header-return {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: right;
    }

    .top-detfs-container-button-wrapper-mobile {
        margin-top: 30px;
        justify-content: center;
        align-items: center;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .top-detfs-container {
        max-width: 959px;
    }

    .top-detfs-boxes {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 24px;
    }

    .top-detfs-box {
        height: 300px;
    }

    .top-detf-box-third {
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .top-detfs-container {
        max-width: 1024px;
    }

    .top-detfs-boxes {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 24px;
    }

    .top-detfs-box {
        height: 300px;
    }

    .top-detf-box-third {
        display: none;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .top-detfs-container {
        max-width: 1280px;
    }

    .top-detfs-boxes {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 24px;
    }

    .top-detfs-box {
        height: 300px;
    }

    .top-detf-box-third {
        display: inherit;
    }
}