.privacy-policy {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.privacy-container {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.privacy-container ul {
    padding-left: 24px;
}

.email-link {
    text-decoration: none;
    color: #000000
}

/* Small devices (mobile phones, <719px) */

@media (max-width: 719px) {
    /* .privacy-policy {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    } */
}

/* Medium devices (tablets, 720px and up) */

@media (min-width: 720px) {
    .privacy-container {
        max-width: 959px;
    }
}

/* Large devices (desktops, 960px and up) */

@media (min-width: 960px) {
    .privacy-container {
        max-width: 1024px;
    }
}

/*Extra large devices (large desktops, 1025px and up)*/

@media (min-width: 1025px) {
    .privacy-container {
        max-width: 1280px;
    }
}