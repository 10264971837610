.title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: left;
    padding-top: 160px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .title-container {
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 80px;
        padding-bottom: 40px;
    }

    .title-container h1 {
        font-size: 40px;
        line-height: 50px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .title-container {
        max-width: 1280px;
    }
}