.progress-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}

.progress-bar-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-bar-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #AAAAAA;
}

.progress-bar-step {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 170px;
    height: 105px;
}


.progress-bar-step-title-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #875CFF;
}

.progress-bar-step-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #AAAAAA;
}

.progress-bar-step-image {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.progress-bar-step-line {
    position: absolute;
    display: flex;
    width: 180px;
    height: 8px;
    background-color: #AAAAAA;
    margin-left: 10px;
    margin-bottom: 11px;
}

.progress-bar-step-line-active {
    position: absolute;
    display: flex;
    width: 180px;
    height: 8px;
    background-color: #AAAAAA;
    margin-left: 10px;
}

.progress-bar-circle {
    position: relative;
    display: flex;
    width: 22px;
    height: 22px;
    background-color: #AAAAAA;
    border-radius: 50%;
    border: 1px solid #AAAAAA;
    margin-bottom: 11px;
}

.progress-bar-circle-active {
    position: relative;
    display: flex;
    width: 44px;
    height: 44px;
    background-color: #875CFF;
    border-radius: 50%;
}


/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .progress-section-container {
        display: none;
    }

    .progress-section-container-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 40px;
    }

    .progress-bar-step-mobile {
        width: 100%;
    }

    .progress-bar-step-title-active {
        margin-bottom: 10px;
    }

    .progress-bar-step-line-active {
        width: 100%;
        margin-left: 0px;
    }

    .progress-bar-step-image-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .progress-section-container-mobile {
        display: none;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .progress-section-container-mobile {
        display: none;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .progress-section-container-mobile {
        display: none;
    }
}