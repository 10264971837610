.hero {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #875CFF;
    color: #ffffff;
}

.hero-container {
    height: 100%;
    display: flex;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

.hero-content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hero-content-title h1 {
    color: #ffffff;
}

.hero-content-title {
    width: 50%;
    height: 100%;
    z-index: 1;
}

.hero-content-title-button-wrapper {
    margin-top: 30px;
}

.hero-content-title h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 30px;
    letter-spacing: -1.5px;
}

.hero-content-title p {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.5px;
}

.hero-content-image-desktop {
    position: absolute;
    height: 661px;
    width: 760px;
    bottom: -202px;
    right: -40px;
    align-items: right;
}


/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .hero {
        margin-top: 60px;
    }

    .hero-container {
        max-width: 719px;
    }

    .hero-content-image-desktop {
        display: none;
    }

    .hero-container {
        height: 100%;
        display: flex;
        padding-top: 80px;
        padding-bottom: 30px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .hero-content-title {
        width: 100%;
    }

    .hero-content-title h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        letter-spacing: -1px;
    }

    .hero-content-title p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.5px;
    }

    .hero-content-image-mobile {
        position: absolute;
        height: 246px;
        width: 254px;
        top: -150px;
        right: 0px;
        align-items: right;
    }

    /* 
    .hero-top-middle {
        height: 73px;
        width: 100%;
        justify-content: right;
        align-items: right;
        background-image: url("../assets/images/geometric-assets-mobile-top-3x.png");
        background-size: 320px 73px;
        background-repeat: no-repeat;
        background-position: bottom right;
    }

    .hero-middle-middle {
        min-height: 200px;
        width: 100%;
        justify-content: right;
        align-items: right;
        background-image: url("../assets/images/geometric-assets-mobile-bottom-3x.png");
        background-size: 320px 148px;
        background-repeat: no-repeat;
        background-position: top right;
    }

    .hero-middle-content {
        margin-left: 24px;
        padding-top: 80px;
        padding-right: 24px;
        padding-bottom: 40px;
    }

    .hero-middle-content h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        letter-spacing: -1px;
    }

    .hero-middle-content p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.5px;
    } */
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .hero-content-image-mobile {
        display: none;
    }

    .hero-container {
        max-width: 959px;
    }

    .hero-content-title {
        width: 75%;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .hero-content-image-mobile {
        display: none;
    }

    .hero-container {
        max-width: 1024px;
    }

    .hero-content-title {
        width: 75%;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    /* .hero {
        height: 476px;
    } */

    .hero-content-image-mobile {
        display: none;
    }

    .hero-container {
        max-width: 1280px;
    }
}