.currency-dropdown {
    border: none;
    border-bottom: 2px solid #000000;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    position: relative;
    background-color: #E3E3E3;
}

.currency-dropdown-box {
    position: absolute;
    top: 29px;
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
    z-index: 9;
}

.currency-dropdown-box-options {
    margin: 0;
    top: 16px;
}

.currency-dropdown-box-options:hover {
    color: #875CFF;
}

.currency-dropdown-box-options:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}