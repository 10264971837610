.detf-summary {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
    margin-bottom: 30px;
}

.detf-summary-line {
    background-color: #000000;
    width: 10px;
}

.detf-summary-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 24px;
}

.detf-summary-info-returns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detf-summary-info-return-one-week,
.detf-summary-info-return-one-month,
.detf-summary-info-return-three-months,
.detf-summary-info-return-one-year {
    width: 25%;
    height: 100%;
}

.detf-summary-info-return-title {
    font-size: 14px;
    font-weight: 700;
}

.detf-summary-info-return-result {
    font-size: 20px;
    font-weight: 700;
    color: #00C213;
}

.detf-summary-info-table {
    margin-top: 10px;
    border-collapse: collapse;
    font-size: 20px;
}

.detf-summary-info-table-cell-title {
    text-align: left;
    vertical-align: top;
    font-weight: 700;
    height: 40px;
}

.detf-summary-info-table-cell-contents {
    text-align: left;
    vertical-align: top;
    padding-left: 24px;
    height: 40px;
}



/* .detf-summary-info-text {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-top: 30px;
}

.detf-summary-info-titles ul {
    list-style-type: none;
    font-size: 20px;
    font-weight: 700;
    margin-top: 30px;
    margin-right: 50px;
}

.detf-summary-info-results ul {
    list-style-type: none;
    font-size: 20px;
    margin-top: 30px;
} */