.deposit-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.deposit-box-container {
    width: 100%;
    background-color: #EEEEEE;
    border-radius: 8px;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    text-align: center;
    padding: 80px;
}

.deposit-box-form {
    padding-top: 60px;
    width: 372px;
}

.deposit-amount-input-title {
    font-size: 16px;
    font-weight: 700;
    color: #C3C3C3;
    text-align: left;
}

.deposit-amount-input {
    border: none;
    border-bottom: 4px solid #000;
    border-radius: 4px;
    height: 66px;
    width: 372px;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    padding: 24px;
    margin-bottom: 30px;
}

.deposit-amount-input::-webkit-outer-spin-button,
.deposit-amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.timelock-selector {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 30px;
}

.timelock-checkbox {
    height: 32px;
    width: 32px;
    background-image: url("../../assets/images/checkbox-unticked.png");
    background-size: contain;
    margin-right: 12px;
}

.timelock-checkbox-ticked {
    height: 32px;
    width: 32px;
    background-image: url("../../assets/images/checkbox-ticked.png");
    background-size: contain;
    margin-right: 12px;
}

.timelock-amount-input-title {
    font-size: 16px;
    font-weight: 700;
    color: #C3C3C3;
    text-align: left;
}

.timelock-amount-input-group {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    width: 372px;
}

.timelock-amount-input {
    border: none;
    border-bottom: 4px solid #000000;
    border-radius: 4px;
    height: 66px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    padding: 24px;
    margin-bottom: 30px;
}

.timelock-amount-input::-webkit-outer-spin-button,
.timelock-amount-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.timelock-date-format {
    background-color: #ffffff;
    border: none;
    border-bottom: 4px solid #000000;
    border-radius: 4px;
    height: 66px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    position: relative;
}

.timelock-existing-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

.timelock-new-inactive {
    display: none;
}

.timelock-existing-inactive {
    display: none;
}

.timelock-new-inputs-inactive {
    display: none;
}

.timelock-existing-inputs-inactive {
    display: none;
}

.deposit-lock-set {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #000000;
    margin-bottom: 30px;
}

.deposit-lock-set-lock-date {
    font-weight: 700;
}

.deposit-lock-set-inactive {
    display: none;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .deposit-box {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .deposit-box-container {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .deposit-box-form {
        padding-top: 30px;
        width: 100%;
    }

    .deposit-amount-input {
        width: 100%;
        padding: 24px;
        margin-bottom: 20px;
    }

    .timelock-checkbox,
    .timelock-checkbox-ticked {
        height: 22px;
        width: 22px;
    }

    .timelock-amount-input-group {
        width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .deposit-box {
        max-width: 1280px;
    }
}