.loading {
    width: 100%;
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    background-color: #E3E3E3;
    padding-top: 200px;
}

.loading-message {
    color: #000000;
    font-weight: 700;
    margin-top: 50px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
}