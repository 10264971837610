.navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

.navbar-container {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.navbar-logo-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-column-gap: 30px;
    list-style: none;
    text-align: center;
    align-items: center;
    justify-content: end;
}

.nav-item {
    height: 80px;
    display: flex;
    align-items: center;
    font-weight: 700;
}

.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #000000;
    border-bottom: 2px solid #000000;
}

.nav-currency {
    border-bottom: 2px solid #E3E3E3;
}

.currency-format {
    border: none;
    border-bottom: 2px solid #000000;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    position: relative;
    background-color: #E3E3E3;
}

.polybit-logo {
    width: 203px;
    height: 57px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .navbar {
        display: none;
    }

    .navbar-mobile {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 719px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 40px;
    }

    .navbar-container-mobile {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .navbar-header-mobile {
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .nav-currency-mobile {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        font-weight: 700;
    }

    .fa-times,
    .fa-bars {
        z-index: 99;
    }

    .nav-menu-mobile {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vh;
        height: 600vw;
        z-index: 98;
        background-color: #875CFF;
        opacity: 96%;
    }

    .menu-icon-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        background-color: #000000;
        color: #ffffff;
        font-size: 28px;
    }

    .nav-menu-mobile-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 99;
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-row-gap: 12px;
        list-style: none;
        text-align: center;
    }

    .nav-item-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-links-mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        font-weight: 700;
        font-size: 24px;
        color: #ffffff;
    }

    .polybit-logo {
        width: 133px;
        height: 37px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .navbar-mobile {
        display: none;
    }

    .nav-currency {
        display: none;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .navbar-mobile {
        display: none;
    }

    .nav-currency {
        display: inherit;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .navbar-mobile {
        display: none;
    }

    .navbar {
        max-width: 1280px;
    }

    .nav-currency {
        display: inherit;
    }
}