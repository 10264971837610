.account-table-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEEEE;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-bottom: 24px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
    color: #909090;
    padding-left: 20px;
    padding-right: 20px;
}

.account-table-row-items {
    width: 100%;
    height: 88px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
}

/* .account-table-row-last {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #EEEEEE;
    margin-bottom: 24px;
    border-radius: 4px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
    color: #909090;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

.account-table-row-last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    border-radius: 4px;
    background-image: linear-gradient(to bottom,
            #EEEEEE,
            #E3E3E3);
    width: 100%;
    height: 100%;
} */

.account-table-row-item-detf {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.account-table-row-item-value {
    width: 15%;
}

.account-table-row-item-return {
    width: 10%;
}

.account-table-row-item-status {
    width: 25%;
}

.account-table-row-item-deposit {
    width: 10%;
    text-align: right;
    vertical-align: middle;
    font-size: 16px;
    color: #000000;
}

.account-table-row-item-toggle {
    width: 10%;
    font-size: 16px;
    color: #000000;
}

.account-table-row-item-toggle-fa {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.account-table-row-loading {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-top: 14px;
    cursor: default;
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.account-table-row-item-logo {
    width: 44px;
    height: 44px;
    margin-right: 10px;
}

.account-table-row-item-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.account-table-row-item-name-link {
    text-decoration: none;
}

.account-table-row-item-category {
    font-size: 16px;
}

.account-table-row-item-dimension {
    font-size: 24px;
    line-height: 24px;
}

.account-table-row-item-link {
    text-decoration: none;
    color: #000000
}

.account-table-row-item-link-to-detf {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    font-weight: 700;
    padding-left: 12px;
}

.account-table-row-expanded {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 50px;
}

.account-table-expanded-divider {
    height: 2px;
    width: 100%;
    background-color: #D6D6D6;
}

.account-table-expanded-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 400
}

.account-table-expanded-content h2 {
    font-size: 24px;
    font-weight: 700;
}

.account-table-expanded-content p {
    font-size: 16px;
}

.account-table-expanded-content-left,
.account-table-expanded-content-right {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: #EEEEEE;
    color: #000;
    margin-top: 40px;
}

.account-table-expanded-content-left {
    padding-right: 20px;
}

.account-table-expanded-content-right {
    padding-left: 20px;
}

.account-table-expanded-content-right h2 {
    font-size: 24px;
    font-weight: 700;
}

.account-table-expanded-content-right h3 {
    font-size: 16px;
    font-weight: 700;
}

.account-table-expanded-content-right p {
    font-size: 16px;
}

.account-table-expanded-content-right-proof-of-assets {
    position: relative;
    margin-top: 50px;
    background-color: #E1E1E1;
    padding-left: 100px;
    padding-right: 24px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 8px;
}

.account-table-expanded-content-right-proof-of-assets p {
    color: #454545;
}

.account-table-expanded-content-right-proof-of-assets a {
    text-decoration: none;
    color: #000000;
}

.account-table-expanded-content-right-proof-of-assets-diamond {
    position: absolute;
    top: 10px;
    left: -30px;
    z-index: 10;
}

.account-table-expanded-content-left-current-value {
    margin-top: 50px;
}

.account-table-expanded-content-left-invested-summary {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    width: 100%;
}

.account-table-expanded-content-left-invested-no-deposits {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.account-table-expanded-content-left-invested-no-deposits-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.account-table-expanded-content-left-invested-summary-line {
    background-color: #000000;
    width: 10px;
}

.account-table-expanded-content-left-invested-summary-table {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
}

.account-table-expanded-content-left-invested-summary-table ul {
    list-style-type: none;
}

.account-table-expanded-content-left-deposit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.account-table-expanded-content-left-close-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    color: #000000;
}

.account-table-expanded-content-left-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.transaction-history td {
    padding-right: 5px;
}

.table-loading {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.account-table-chart-title {
    margin-bottom: 20px;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .account-table-row {
        display: none;
    }

    .account-table-row-mobile {
        width: 100%;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #EEEEEE;
        border: 1px solid #fff;
        border-radius: 4px;
        margin-bottom: 24px;
        cursor: default;
        font-size: 20px;
        font-weight: 700;
        color: #909090;
    }

    .account-table-row-items-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 4px;
    }

    .account-table-row-item-detf-header-mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: left;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
    }

    .account-table-row-item-detf-mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .account-table-row-item-name-mobile {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
    }

    .account-table-row-item-logo-mobile {
        width: 48px;
        height: 48px;
        margin-right: 12px;
    }

    .account-table-row-item-name-category-mobile {
        font-size: 16px;
    }

    .account-table-row-item-name-dimension-mobile {
        color: #000000;
        font-size: 20px;
        line-height: 20px;
    }

    .account-table-row-item-table-mobile {
        width: 100%;
        padding: 24px;
        color: #909090;
        font-size: 16px;
    }

    .account-table-row-item-table-header-mobile {
        width: 50%;
        text-align: right;
        vertical-align: top;
        padding-right: 6px;
        line-height: 20px;
    }

    .account-table-row-item-table-cell-mobile {
        width: 50%;
        text-align: left;
        vertical-align: top;
        padding-left: 6px;
        line-height: 20px;
    }

    .account-table-row-expanded-mobile {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .account-table-row-expanded-wrapper-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
    }

    .account-table-expanded-divider-mobile {
        height: 2px;
        width: 100%;
        background-color: #D6D6D6;
    }

    .account-table-expanded-content-mobile {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        padding-top: 24px;
    }

    .account-table-expanded-content-invested-summary-mobile {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        width: 100%;
    }

    .account-table-expanded-content-invested-no-deposits-mobile {
        margin-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .account-table-expanded-content-invested-no-deposits-icon-mobile {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    .account-table-expanded-content-invested-summary-line-mobile {
        background-color: #000000;
        width: 10px;
    }

    .account-table-expanded-content-invested-summary-table-mobile {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
    }

    .account-table-expanded-content-invested-summary-table-mobile ul {
        list-style-type: none;
    }

    .account-table-expanded-content-deposit-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
    }

    .account-table-expanded-content-current-value-mobile {
        margin-top: 24px;
    }

    .account-table-expanded-content-close-message-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 24px;
    }

    .account-table-expanded-content-close-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .account-table-expanded-content-owned-assets-mobile {}

    .account-table-expanded-content-proof-of-assets-mobile {
        position: relative;
        background-color: #E1E1E1;
        margin-top: 24px;
        padding-left: 100px;
        padding-right: 24px;
        padding-top: 24px;
        padding-bottom: 24px;
        font-size: 16px;
        font-weight: 400;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        color: #000000;
    }

    .account-table-expanded-content-proof-of-assets-mobile p {
        color: #454545;
    }

    .account-table-expanded-content-proof-of-assets-mobile a {
        text-decoration: none;
        color: #000000;
    }

    .account-table-expanded-content-proof-of-assets-diamond-mobile {
        position: absolute;
        top: 10px;
        left: -30px;
        z-index: 10;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .account-table-row-mobile {
        display: none;
    }

    .account-table-row-item-status {
        display: none;
    }

    .account-table-row-item-detf {
        width: 40%;
    }

    .account-table-row-item-value,
    .account-table-row-item-return {
        width: 15%;
    }

    .account-table-row-item-deposit,
    .account-table-row-item-toggle {
        width: 15%;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .account-table-row-mobile {
        display: none;
    }

    .account-table-row-item-status {
        display: inherit;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .account-table-row-mobile {
        display: none;
    }

    .account-table-row {
        max-width: 1280px;
    }

    .account-table-row-item-status {
        display: inherit;
    }
}