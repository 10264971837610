.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.footer-container {
    width: 100%;
    justify-content: left;
    align-items: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 80px;
    padding-bottom: 60px;
}

.footer-top {
    display: flex;
    flex-direction: row;
}

.footer-logo-wrapper {
    height: 28px;
    width: 100px;
}

.footer-bottom {
    display: flex;
    flex-direction: column;
}

.footer-link-items {
    display: flex;
    color: #fff;
    margin-left: 100px;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.footer-link-items h2 {
    font-weight: 700;
}

.footer-link-items a {
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}

.footer-copyright-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: right;
    border-top: 1px solid #fff;
}

.footer-copyright-text {
    color: #fff;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .footer-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 42px;
        padding-bottom: 42px;
    }

    .footer-top {
        margin-bottom: 62px;
        display: flex;
        flex-direction: column;
    }

    .footer-link-items {
        flex-direction: column;
        margin-left: 0px;
        margin-top: 40px;
        width: 160px;
    }

    .footer-link-items h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .footer-link-items a {
        font-size: 16px;
        line-height: 44px;
    }

    .footer-copyright-text {
        font-size: 12px;
        margin-top: 40px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .footer-container {
        max-width: 959px;
    }

    .footer-top {
        margin-bottom: 130px;
    }

    .footer-link-items {
        width: 160px;
    }

    .footer-link-items h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .footer-link-items a {
        font-size: 16px;
        line-height: 44px;
    }

    .footer-copyright-text {
        font-size: 12px;
        margin-top: 40px;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .footer-container {
        max-width: 1024px;
    }

    .footer-top {
        margin-bottom: 130px;
    }

    .footer-link-items {
        width: 160px;
    }

    .footer-link-items h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .footer-link-items a {
        font-size: 16px;
        line-height: 44px;
    }

    .footer-copyright-text {
        font-size: 12px;
        margin-top: 40px;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .footer-container {
        max-width: 1280px;
    }

    .footer-top {
        margin-bottom: 130px;
    }

    .footer-link-items {
        width: 160px;
    }

    .footer-link-items h2 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .footer-link-items a {
        font-size: 16px;
        line-height: 44px;
    }

    .footer-copyright-text {
        font-size: 12px;
        margin-top: 40px;
    }
}