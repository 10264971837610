.detf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
}

.detf-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.detf-title-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.detf-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-column-gap: 40px;
    list-style: none;
    margin-top: 80px;
}

.detf-content-a,
.detf-content-b {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.detf-name-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 700;
}

.detf-name-logo {
    width: 88px;
    height: 88px;
    margin-right: 10px;
}

.detf-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.detf-name-category {
    font-size: 32px;
    line-height: 32px;
}

.detf-name-dimension {
    color: #000000;
    font-size: 48px;
    line-height: 48px;
}

.detf-description {
    margin-bottom: 60px;
}

.detf-chart {
    margin-bottom: 60px;
}

.detf-chart-title {
    margin-bottom: 20px;
}

.detf-chart p {
    font-size: 14px;
    font-weight: 700;
}

.detf-summary-fine-print {
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: #909090;
}

.detf-assets-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: #EEEEEE;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 30px;
    color: #676767;
    margin-bottom: 60px;
}

.detf-assets-box h2 {
    font-size: 24px;
    font-weight: 700;
    color: #909090;
}

.detf-assets-box p {
    font-size: 16px;
}

.native-token-message-box {
    border-radius: 8px;
    background-color: #D9D9D9;
    border: 1px solid #ECECEC;
    padding: 30px;
}

.detf-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}


/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .detf-button-wrapper {
        display: none;
    }

    .detf {
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .detf-content {
        margin-top: 40px;
    }

    .detf-name-logo {
        width: 57px;
        height: 57px;
    }

    .detf-name-category {
        font-size: 21px;
        line-height: 21px;
    }

    .detf-name-dimension {
        font-size: 32px;
        line-height: 32px;
    }

    .detf-button-wrapper-mobile {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .detf-description {
        margin-bottom: 30px;
    }

    .detf-chart {
        margin-bottom: 30px;
    }

    .detf-summary-info-return-one-week,
    .detf-summary-info-return-one-month,
    .detf-summary-info-return-three-months {
        width: 33%;
        height: 10px;
    }

    .detf-summary-info-return-one-year {
        display: none;
    }

    .detf-assets-box {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 30px;
    }

    .native-token-message-box {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 24px;
        padding-right: 24px;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .detf-button-wrapper-mobile {
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .detf-button-wrapper-mobile {
        display: none;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .detf-button-wrapper-mobile {
        display: none;
    }

    .detf {
        max-width: 1280px;
    }
}