.category {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 160px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: hidden;
}

.category-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.category-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.category-title-name-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-weight: 700;
    margin-bottom: 10px;
}

.category-title-name-logo {
    width: 72px;
    height: 72px;
    margin-right: 24px;
}

.category-title-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
}

.category-main-desktop {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    margin-bottom: 80px;
}

.category-main-content-desktop {
    width: 50%;
    height: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: top;
}

.category-main-content-desktop p {
    white-space: pre-wrap;
}

.category-icons-container-desktop {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.category-main-mobile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: top;
    margin-top: 40px;
    margin-bottom: 40px;
}

.category-main-content-mobile {
    width: 100%;
    height: 50%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: top;
}

.category-main-content-mobile p {
    white-space: pre-wrap;
}

.category-icons-container-mobile {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.category-icons {
    width: 440px;
    height: 368px;
    position: relative;
}

.category-icon {
    position: absolute;
}

.category-cta {
    font-size: 40px;
    font-weight: 700;
}

.category-detfs-container {
    width: 100%;
}

.category-detf-boxes {
    display: grid;
    margin-top: 60px;
    list-style: none;
}

.category-detf-box {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 8px;
    border: 1px solid white;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

.category-detf-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end
}

.category-detf-name-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    margin-bottom: 10px;
}

.category-detf-name-logo {
    width: 48px;
    height: 48px;
    margin-right: 10px;
}

.category-detf-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
}

.category-detf-name-category {
    font-size: 16px;
    line-height: 16px;
}

.category-detf-name-dimension {
    color: #000000;
    font-size: 24px;
    line-height: 24px;
}

.category-detf-return {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;
    text-align: right;
    font-size: 20px;
    font-weight: 700;
}

.category-detf-return-title {
    font-size: 16px;
    line-height: 16px;
}

.category-detf-chart {
    margin-top: 60px;
    margin-bottom: 60px;
    height: 100px;
    width: 100%;
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .category {
        max-width: 719px;
        padding-top: 40px;
        padding-bottom: 80px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .category-main-desktop {
        display: none;
    }

    .category-detf-boxes {
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-row-gap: 30px;
        margin-top: 30px;
    }

    .category-title-name-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .category-title-name-logo {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .category-title-name {
        display: flex;
        flex-direction: column;
        align-items: left;
        font-size: 40px;
        line-height: 40px;
    }

}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .category {
        max-width: 959px;
    }

    .category-main-mobile {
        display: none;
    }

    .category-main-desktop {
        max-width: 959px;
    }

    .category-detf-boxes {
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-row-gap: 60px;
    }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .category {
        max-width: 1024px;
    }

    .category-main-mobile {
        display: none;
    }

    .category-main-desktop {
        max-width: 1024px;
    }

    .category-detf-boxes {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 60px;
        grid-row-gap: 60px;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .category {
        max-width: 1280px;
    }

    .category-main-mobile {
        display: none;
    }

    .category-main-desktop {
        max-width: 1280px;
    }

    .category-detf-boxes {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 60px;
        grid-row-gap: 60px;
    }
}