.locked-beta {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.locked-beta-top-container {
    height: 100%;
    width: 100%;
    display: flex;
    padding-top: 160px;
    padding-bottom: 80px;
    padding-left: 40px;
    padding-right: 40px;
}

.locked-beta-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.locked-beta-content-title {
    z-index: 1;
}

.locked-beta-content-title h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 54px;
    margin-bottom: 30px;
    letter-spacing: -1.5px;
}

.locked-beta-content-title p {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: -0.5px;
}

.locked-beta-content-title-twitter-wrapper {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.locked-beta-content-image-desktop {
    position: absolute;
    height: 659px;
    width: 752px;
    bottom: -202px;
    right: -170px;
    align-items: right;
}

.locked-beta-bottom-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: #CCCCCC;
}


.twitter-follow-button,
.twitter-follow-button:visited {
    background-color: #1DA1F2;
    color: #fff;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    height: 34px;
    font-weight: 500;
    font-size: 13px;
    padding: 8px 8px 8px 30px;
    text-decoration: none;
    background-image: url("../assets/images/twitter.png");
    background-repeat: no-repeat;
    background-size: 16px 13px;
    background-position: 8px 10px;
}

.twitter-follow-button:hover {
    background-color: #105b89;
}


/* Small devices (landscape phones, 576px and up) */

@media (max-width: 719px) {
    .locked-beta {
        margin-top: 60px;
    }

    .locked-beta-top-container {
        max-width: 719px;
    }

    .locked-beta-content-image-desktop {
        display: none;
    }

    .locked-beta-top-container {
        height: 100%;
        display: flex;
        padding-top: 80px;
        padding-bottom: 30px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .locked-beta-content-title {
        width: 100%;
    }

    .locked-beta-content-title h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
        letter-spacing: -1px;
    }

    .locked-beta-content-title p {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.5px;
    }

    .locked-beta-content-image-mobile {
        position: absolute;
        width: 261px;
        height: 238px;
        top: -120px;
        right: -60px;
        align-items: right;
    }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 720px) {
    .locked-beta-content-image-mobile {
        display: none;
    }

    .locked-beta-top-container {
        max-width: 959px;
    }

    .locked-beta-content-title {
        width: 50%;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 960px) {
    .locked-beta-content-image-mobile {
        display: none;
    }

    .locked-beta-top-container {
        max-width: 1024px;
    }

    .locked-beta-content-title {
        width: 50%;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1025px) {
    .locked-beta-content-image-mobile {
        display: none;
    }

    .locked-beta-top-container {
        max-width: 1280px;
    }

    .locked-beta-content-title {
        width: 40%;
    }
}